import { createSlice } from '@reduxjs/toolkit'
import name from './name'
import { resetStore } from '../utils'

const initialState = {
  patients: [],
  searchPatients: [],
  patientDoctors: []
}

export const patientSlice = createSlice({
  name,
  initialState,
  reducers: {
    reset: (state) => {
      resetStore(state, initialState)
    },
    setSearchPatients: (state, { payload }) => {
      const { searchPatients } = state
      for (const patient of payload) {
        const index = searchPatients.findIndex(({ doc }) => doc === patient.doc)
        if (index >= 0) {
          state.searchPatients[index] = patient
        } else {
          state.searchPatients = [...state.searchPatients, patient]
        }
      }
    },
    clearSearchPatients: (state) => {
      state.searchPatients = []
    },
    setPatients: (state, { payload }) => {
      const { patients } = state
      for (const patient of payload) {
        const index = patients.findIndex(({ doc }) => doc === patient.doc)
        if (index >= 0) {
          state.patients[index] = patient
        } else {
          state.patients = [...state.patients, patient]
        }
      }
    },
    setPatientDoctors: (state, { payload }) => {
      state.patientDoctors = payload
    },
    tooglePatientActive: (state, { payload }) => {
      const { doc, active } = payload
      state.searchPatients = state.searchPatients?.map((patient) =>
        patient?.doc === doc ? { ...patient, active } : patient
      )
      state.patients = state.patients?.map((patient) =>
        patient?.doc === doc ? { ...patient, active } : patient
      )
    }
  }
})

export const {
  setSearchPatients,
  clearSearchPatients,
  setPatients,
  setPatientDoctors,
  tooglePatientActive,
  reset
} = patientSlice.actions

export default patientSlice.reducer
