import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { adminSlice, patientSlice } from '../../../../reducers'
import { useEvent } from '@emerald-works/react-event-bus-client'
import Loading from '../../../loading'
import SearchIcon from '@mui/icons-material/Search'
import { useParams } from 'react-router-dom'
import { PagesContext } from '../../../../contexts/pages'
import PersonOutlineIcon from '@mui/icons-material/PersonOutline'
import { applyCpfString, fixCpfString } from '../../../../utils/mask'

const ListPatients = () => {
  const { setPatientPage, AdminPatientPages } = React.useContext(PagesContext)
  const [currentPage, setCurrentPage] = React.useState(1)
  const [search, setSearch] = React.useState('')
  const [active, setActive] = React.useState(true)
  const [isSearching, setIsSearching] = React.useState(false)
  const [searchBy, setSearchBy] = React.useState('name')
  const dispatcher = useDispatch()
  const { tenantKey } = useParams()
  const usersPerPage = 9
  const indexOfLastPatient = currentPage * usersPerPage
  const indexOfFirstPatient = indexOfLastPatient - usersPerPage
  const patients = useSelector(patientSlice.selectors.selectPatients)
  const patientsFromSearch = useSelector(
    patientSlice.selectors.selectSearchPatients
  )
  const patientsList = React.useMemo(() => (isSearching ? patientsFromSearch : patients).filter(p => active ? p?.active !== false : p?.active === false), [isSearching, patientsFromSearch, patients, active])
  const currentPatients = patientsList.slice(
    indexOfFirstPatient,
    indexOfLastPatient
  )
  const startAt = React.useMemo(() => (currentPatients[currentPatients.length - 1]?.doc ? { tenantKey, doc: currentPatients[currentPatients.length - 1]?.doc } : null), [tenantKey, currentPatients])
  const pages = Math.ceil(patientsList.length / usersPerPage)
  const [alreadyOnLastPage, setAlreadyOnLastPage] = React.useState(false)
  const [clean, setClean] = React.useState(false)

  const paginate = pageNumber => setCurrentPage(pageNumber)

  const [getPatients, searchPatients] = useEvent([
    patientSlice.eventBus.getPatients,
    patientSlice.eventBus.searchPatients
  ])

  const isWorking = getPatients.isWorking || searchPatients.isWorking

  const requestUsers = () => {
    if (currentPage === pages) {
      if (isSearching) {
        searchForPatients()
      } else {
        getPatients.trigger({ limit: usersPerPage, startAt, active })
      }
    }
    paginate(currentPage + 1)
  }

  React.useEffect(() => {
    getPatients.trigger({ limit: usersPerPage, active })
  }, [getPatients, active])

  const setPatient = adminPatient => {
    dispatcher(adminSlice.actions.setPatient(adminPatient))
  }

  React.useEffect(() => {
    if (
      currentPatients.length < usersPerPage &&
      currentPatients.length > 0
    ) {
      setAlreadyOnLastPage(true)
    }
  }, [currentPatients])

  const searchForPatients = () => {
    setIsSearching(true)
    if (clean) {
      dispatcher(patientSlice.actions.clearSearchPatients()) // clean users from previous search
      searchPatients.trigger({ limit: usersPerPage, search, searchBy, active })
      setClean(false)
    } else {
      searchPatients.trigger({ limit: usersPerPage, search, searchBy, startAt, active })
    }
    setCurrentPage(1)
    setAlreadyOnLastPage(false)
  }

  const endSearching = () => {
    setSearch('')
    setIsSearching(false)
    setActive(true)
    dispatcher(patientSlice.actions.clearSearchPatients())
    setCurrentPage(1)
    setAlreadyOnLastPage(false)
  }

  React.useEffect(() => {
    setCurrentPage(1)
  }, [isSearching])

  const isAbleToSearch = (search.length >= 3) && !searchPatients.isWorking

  return (
    <section className='px-2 flex flex-col justify-between min-h-[90vh]'>
      <div>
        <div className='flex justify-between'>
          <h1 className='title'>Pacientes</h1>
          <button
            type='button'
            onClick={() => { setPatientPage(AdminPatientPages.ADMIN_PATIENT_CREATE) }}
            className='btn-primary'
          >
            + <span>Novo Paciente</span>
          </button>
        </div>
        <div className='flex flex-col xl:flex-row mt-3'>
          <div className='flex md:flex-row flex-col'>
            <div className='flex items-center border-b flex-1'>
              <SearchIcon />
              <input
                value={searchBy === 'doc' ? applyCpfString(search) : search}
                onChange={e => {
                  setSearch(searchBy === 'doc' ? fixCpfString(e.target.value) : e.target.value)
                  setClean(true)
                }}
                type='search'
                name='user-name'
                id='user-name'
                placeholder='Buscar paciente'
                className='py-3 px-4 lg:w-auto flex-1 text-sm bg-transparent outline-none'
              />
            </div>
            <div className='flex flex-col px-2'>
              <label className='text-secondary-gray text-xs' htmlFor='searchBy'>
                Buscar usando
              </label>
              <label className='flex' id='searchBy'>
                <div
                  onClick={() => setSearchBy('name')}
                  className={`text-sm px-4 py-1 rounded-md cursor-pointer bg-opacity-10 ${searchBy === 'name'
                    ? 'bg-secondary-gray'
                    : 'bg-transparent'}`}
                >
                  Nome
                </div>
                <div
                  onClick={() => setSearchBy('doc')}
                  className={`text-sm px-4 py-1 rounded-md cursor-pointer bg-opacity-10 ${searchBy === 'doc'
                    ? 'bg-secondary-gray'
                    : 'bg-transparent'}`}
                >
                  Documento
                </div>
              </label>
            </div>
          </div>
          <div className='flex flex-row items-center'>
            <input type='checkbox' className='mr-2 cursor-pointer' checked={!active} onClick={() => setActive(active => !active)} />
            <label>Inativos</label>
          </div>
          <button
            onClick={searchForPatients}
            className={`btn-primary ${!isAbleToSearch ? 'opacity-50' : ''}`}
            disabled={!isAbleToSearch}
          >
            Buscar
          </button>
          {isSearching && (
            <button onClick={endSearching} className='btn-secondary'>
              Limpar Busca
            </button>
          )}
        </div>
        {isWorking ? (
          <div className='w-full flex justify-center p-10'>
            <Loading />
          </div>
        ) : !currentPatients.length ? (
          <div className='w-full flex justify-center p-10'>
            Não foram encontrados pacientes.
          </div>
        ) : (
          <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-4'>
            {currentPatients.map((patient, index) => (
              <div
                key={index}
                className='flex flex-col border p-4 gap-6 lg:max-w-md rounded-md border-slate-300 shadow-xl cursor-pointer'
                onClick={() => {
                  setPatient(patient)
                }}
              >
                <div className='flex justify-between items-center w-full'>
                  <div className='truncate'>
                    <h1 className='font-semibold text-sm truncate'>
                      {patient.name}
                    </h1>
                    <p className='text-[12px] text-secondary-gray'>{applyCpfString(patient.doc)}</p>
                  </div>
                  <PersonOutlineIcon className='icon' />
                </div>
                {patient.supervisorInfo && <h1 className='text-sm'><b>Supervisor: </b>{patient.supervisorInfo.name} - {patient.supervisorInfo.email}</h1>}
                <div className='grid grid-cols-2 gap-2'>
                  <div className='flex flex-col'>
                    <h1 className='font-semibold text-sm'>Profissionais</h1>
                    {patient?.doctors.length === 0 && <p className='text-[12px]'>Não há profissionais para este paciente.</p>}
                    <ul className='list-disc pt-1 pl-1'>
                      {patient?.doctors?.map((doctor, index) => (
                        <li key={index} className='text-[12px] truncate'>
                          {doctor.name}
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className='flex flex-col'>
                    <h1 className='font-semibold text-sm'>Familiares</h1>
                    {patient?.family?.length === 0 && <p className='text-[12px]'>Não há familiares para este paciente.</p>}
                    <ul className='list-disc pt-1 pl-1'>
                      {patient?.family?.map((relative, index) => (
                        <li key={index} className='text-[12px] truncate'>
                          {relative.name}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            ))}
          </div>)}
      </div>
      <div className='pagination max-w-5xl mx-auto flex justify-center items-center'>
        {[...Array(pages).keys()].map(number => (
          <button
            className={`border p-2 rounded ${currentPage === number + 1
              ? 'bg-secondary-blue text-white'
              : 'bg-white text-secondary-blue'}`}
            key={number}
            onClick={() => paginate(number + 1)}
          >
            {number + 1}
          </button>
        ))}
        {!alreadyOnLastPage && patientsList.length > 0 && !clean && (
          <button
            className='m-2 shadow bg-white p-2 rounded text-secondary-blue'
            onClick={requestUsers}
          >
            {currentPage === pages ? 'Carregar mais' : '>'}
          </button>
        )}
      </div>
    </section>
  )
}

export default ListPatients
