import * as actions from './slice'
import * as patientActions from '../patient/slice'

export const updateUser = {
  eventName: 'updateUser'
}

export const createUser = {
  eventName: 'createUser'
}

export const createPatient = {
  eventName: 'createPatient'
}

export const getPatient = {
  eventName: 'getPatient',
  onSuccess: {
    action: actions.setPatient,
    redux: true
  }
}

export const getDoctor = {
  eventName: 'getUserByPk',
  onSuccess: {
    action: actions.setDoctor,
    redux: true
  }
}

export const getSubscription = {
  eventName: 'getSubscription',
  onSuccess: {
    action: actions.setSubscriptionAndPlan,
    redux: true
  }
}

export const getCreditCards = {
  eventName: 'getCreditCards',
  onSuccess: {
    action: actions.setCreditCards,
    redux: true
  }
}

export const getPlans = {
  eventName: 'getPlans',
  onSuccess: {
    action: actions.setPlans,
    redux: true
  }
}

export const removeCreditCard = {
  eventName: 'removeCreditCard'
}

export const addCreditCard = {
  eventName: 'addCreditCard'
}

export const cancelSubscription = {
  eventName: 'cancelSubscription'
}

export const newSubscription = {
  eventName: 'newSubscription'
}

export const getCharges = {
  eventName: 'getCharges',
  onSuccess: {
    action: actions.setCharges,
    redux: true
  }
}

export const updatePatient = {
  eventName: 'updatePatient'
}

export const getSignatureDocuments = {
  eventName: 'getSignatureDocuments',
  onSuccess: {
    action: actions.setSignatureDocuments,
    redux: true
  }
}

export const tooglePatientActive = {
  eventName: 'tooglePatientActive',
  onSuccess: {
    action: patientActions.tooglePatientActive,
    redux: true
  }
}
