import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { adminSlice, patientSlice } from '../../../../reducers'
import { useEvent } from '@emerald-works/react-event-bus-client'
import ListDoctors from './list-doctors'
import ListFamily from './list-family'

const EditPatient = () => {
  const adminPatient = useSelector(adminSlice.selectors.selectPatient)
  const patient = useSelector(adminSlice.selectors.selectPatient)
  const [selectedPatient, setSelectedPatient] = React.useState(adminPatient)
  const [getPatient] = useEvent([adminSlice.eventBus.getPatient])
  const [updatePatientInfo, tooglePatientActive] = useEvent([adminSlice.eventBus.updatePatient, adminSlice.eventBus.tooglePatientActive])

  const dispatcher = useDispatch()
  const setPatient = adminPatient => {
    dispatcher(adminSlice.actions.setPatient(adminPatient))
  }

  const setPatientDoctors = doctors => {
    dispatcher(patientSlice.actions.setPatientDoctors(doctors))
  }

  const updatePatient = React.useCallback((field, value) => {
    setSelectedPatient({ ...selectedPatient, [field]: value })
  }, [selectedPatient])

  React.useEffect(() => {
    if (selectedPatient?.patientDoc && !patient) {
      getPatient.trigger({ patientDoc: selectedPatient?.patientDoc })
    }
  }, [getPatient, patient, selectedPatient])

  const updatedPatient = React.useMemo(() => ({ ...selectedPatient }), [selectedPatient])

  const save = React.useCallback(() => {
    const updatedInfo = { ...updatedPatient }
    updatePatientInfo.trigger(updatedInfo)
    dispatcher(adminSlice.actions.setPatient(updatedPatient))
  }, [dispatcher, updatedPatient])

  const toogleActive = React.useCallback(() => {
    tooglePatientActive.trigger(patient)
    dispatcher(adminSlice.actions.setPatient({ ...patient, active: patient?.active === false ? true : false }))
  }, [dispatcher, patient])

  const isAbleToSave = React.useMemo(() => !updatePatientInfo.isWorking && !getPatient.isWorking && JSON.stringify(updatedPatient) !== JSON.stringify(adminPatient), [adminPatient, getPatient.isWorking, updatePatientInfo.isWorking, updatedPatient])

  return (
    <section className='px-2 font-inter flex flex-col w-full'>
      <div className='inline-flex items-center justify-between'>
        <div className='flex title w-full justify-start'>
          <button
            className='outline-none border-none'
            onClick={() => {
              setPatient(null)
            }}
          >
            Pacientes
          </button>
          <span>{'>'}</span>
          <p className='truncate'>{adminPatient.name}</p>
        </div>
      </div>
      <div className='flex justify-between'>
        <button className={patient?.active === false ? 'btn-primary' : 'btn-secondary'} onClick={toogleActive} disabled={tooglePatientActive.isWorking}>{patient?.active === false ? 'Ativar Paciente' : 'Desativar Paciente'}</button>
        <div className='flex-row flex'>
          <button className={isAbleToSave ? 'btn-primary' : 'btn-disabled'} disabled={!isAbleToSave} onClick={save}>Salvar</button>
          <button className='btn-secondary' onClick={() => { setPatient(null); setPatientDoctors([]) }}>Voltar</button>
        </div>
      </div>
      <div className='w-full'>
        <div className='mb-5'>
          <label htmlFor='name' className='label'>Nome</label>
          <input type='text' id='name' className='input' value={selectedPatient?.name} onChange={({ target: { value } }) => updatePatient('name', value)} />
        </div>
        <div className='py-4 border-b'>
          <ListFamily />
        </div>
        <div className='py-4 border-b'>
          <ListDoctors />
        </div>
      </div>
    </section>
  )
}

export default EditPatient
